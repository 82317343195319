// Produkt wyświetlany na listach i sliderach

import React, { useState, FC, useMemo, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useSelector } from 'store';
import { useRWD } from 'hooks';
import { IProductListItem } from 'api/types';
import { AddToShoppingListButton } from 'components/containers';
import { Button, Link, Label, Checkbox } from 'components/controls';

import { TrashIcon } from 'assets/icons';

import styles from 'theme/components/containers/ProductItem/ProductItem.module.scss';

// typ danych wejściowych
interface Props {
  product: IProductListItem;
  categoryId?: number;
  searchKeywords?: string;
  mode?: string;
  line?: boolean;
  minimalVariant?: boolean;
  imageStretch?: boolean;
  transparent?: boolean;
  isSearch?: boolean;
  isShoppingList?: boolean;
  isChecked?: boolean;
  withDynamicGallery?: boolean;
  withHoverEffect?: boolean;
  hideAvailability?: boolean;
  isSlider?: boolean;
  toggleCheckbox?: () => void;
  handleRemove?: () => void;
}

const ProductItem: FC<Props> = ({
  product,
  categoryId,
  searchKeywords,
  mode,
  line,
  minimalVariant,
  imageStretch,
  transparent,
  isSearch,
  isShoppingList,
  isChecked,
  withDynamicGallery,
  withHoverEffect,
  isSlider,
  toggleCheckbox,
  handleRemove
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { profile } = useSelector((state) => state.auth);
  const { isMobile } = useRWD();

  // aktualna ilość
  const [quantity, setQuantity] = useState(0);

  // aktualna jednostka
  const unit = useMemo(
    () => product.units.find((unit) => unit.unit_id === product.units[0]?.unit_id),
    [product]
  );

  // aktualnie wybrany obrazek w galerii
  const [galleryIndex, setGalleryIndex] = useState(0);

  // zmiana ilości po zmianie jednostki
  useEffect(() => {
    unit && setQuantity(unit.minimal_quantity || 1);
  }, [unit]);

  // funkcja renderująca przycisk dodawania produktu do listy zakupowej
  const renderAddToShoppingListButton = useCallback(
    () => (
      <div
        className={classnames(styles.shoppingListButtonWrapper, {
          [styles.shoppingListButtonWrapperLine]: !!line
        })}>
        {profile?.role !== 'ROLE_OPEN_PROFILE' && (
          <AddToShoppingListButton
            product={product}
            unit={unit}
            quantity={quantity}
            isShoppingList={isShoppingList}
          />
        )}
      </div>
    ),
    [profile, quantity, unit]
  );

  const onHover = () => {
    const slick = document.querySelector('.slick-list') as HTMLElement;
    const slickItemsNotActive = document.querySelectorAll(
      '.slick-slide:not(.slick-active)'
    ) as NodeListOf<HTMLElement>;

    if (slick && slick.style.overflow !== 'visible') {
      slick.style.overflow = 'visible';
      slickItemsNotActive.forEach((item: HTMLElement) => (item.style.visibility = 'hidden'));
    } else {
      slick.style.overflow = 'hidden';
      slickItemsNotActive.forEach((item: HTMLElement) => (item.style.visibility = 'visible'));
    }
  };

  const renderTitle = () => {
    return (
      <div
        className={classnames(styles['product-title'], {
          [styles.productTitleLine]: !!line
        })}>
        {line && !isSearch ? (
          <>
            <span itemProp="name" className={styles.title}>
              {product.title}
            </span>
            <meta itemProp="name" content={product.title} />
            {!(isMobile && isShoppingList) && (
              <Link to={`/${product.url_link}`} state={{ categoryId, searchKeywords, mode }}>
                <Button ghost square>
                  <Trans>Pokaż</Trans>
                </Button>
                <meta itemProp="url" content={window.location.origin + `/products/${product.id}`} />
              </Link>
            )}
          </>
        ) : (
          <>
            <Link
              className={styles.title}
              to={`/${product.url_link}`}
              state={{ categoryId, searchKeywords, mode }}>
              <meta itemProp="url" content={window.location.origin + `/products/${product.id}`} />
              <span itemProp="name">{product.title}</span>
            </Link>
            {isSearch && (
              <div className={styles.variants}>
                <span>
                  <Trans>Rozmiar</Trans>: {product.sizes}
                </span>
              </div>
            )}
          </>
        )}

        {minimalVariant && (
          <div
            className={classnames(styles.priceWrapper, {
              [styles.priceWrapperLine]: !!line
            })}>
            {unit?.old_price_net_formatted && (
              <div
                className={classnames(styles.old, {
                  [styles.oldLine]: !!line
                })}>
                {unit.old_price_net_formatted.replace('.', ',')} {product.currency}
              </div>
            )}
            <div className={styles.net}>
              <strong>
                {unit?.price_net_formatted} {product.currency}
              </strong>{' '}
              {t('netto')}
            </div>
            <div className={styles.gross}>
              {unit?.price_net_formatted.replace('.', ',')} {product.currency} {t('netto')}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderPriceWrapper = () => {
    return (
      <div
        className={classnames(styles.priceWrapper, {
          [styles.priceWrapperLine]: !!line
        })}>
        {unit?.old_price_net_formatted && !isSearch && (
          <div
            className={classnames(styles.old, {
              [styles.oldLine]: !!line
            })}>
            {unit.old_price_net_formatted.replace('.', ',')} {product.currency} {t('netto')}
          </div>
        )}
        <div
          className={classnames(styles.net, {
            [styles.netLine]: !!line
          })}>
          <span>
            <span className={styles.netValue}>
              {product.price_formatted_to_show?.replaceAll('.', ',')} {product.currency}
            </span>{' '}
            {!!line && !isSearch && <br />} <span>{t('netto')}</span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        {
          [styles.lineWrapper]: !!line,
          [styles.transparent]: !!transparent,
          [styles.isSearch]: !!isSearch,
          [styles.isShoppingList]: !!isShoppingList,
          [styles.withDynamicGallery]: !!withDynamicGallery && !isMobile,
          [styles.isSlider]: !!isSlider
        },
        'StylePath-Components-Containers-ProductItem'
      )}
      {...(withHoverEffect && {
        onMouseOver: onHover,
        onMouseOut: onHover
      })}
      itemProp="item"
      itemScope
      itemType="http://schema.org/Product">
      {isShoppingList && (
        <div>
          <Checkbox checked={!!isChecked} onClick={toggleCheckbox} />
        </div>
      )}
      <div
        className={classnames(styles.imageTitleWrapper, {
          [styles.imageTitleWrapperLine]: !!line,
          [styles.imageStretch]: imageStretch
        })}>
        <Link
          to={`/${product.url_link}`}
          state={{ categoryId, searchKeywords, mode }}
          className={classnames(styles['product-image'], {
            [styles.productImageLine]: !!line,
            [styles.productImageMinimal]: !!minimalVariant
          })}>
          <img itemProp="image" src={product.images[galleryIndex]?.min} alt={product.title} />
          {product.labels.length > 0 && !isSearch && (
            <div className={classnames(styles.labels)}>
              {product.labels.map((label) => (
                <Label key={label.type} label={label} />
              ))}
            </div>
          )}
        </Link>
        {minimalVariant && renderTitle()}
      </div>

      <div
        className={classnames(styles['product-details'], {
          [styles.productDetailsLine]: !!line,
          [styles.noActions]: profile?.role === 'ROLE_OPEN_PROFILE'
        })}>
        {!minimalVariant && renderTitle()}

        <div
          className={classnames(styles['product-actions'], {
            [styles.productActionsLine]: !!line
          })}>
          {profile?.role !== 'ROLE_OPEN_PROFILE' &&
            !minimalVariant &&
            !(isMobile && isShoppingList) &&
            !(isSearch && isMobile) &&
            renderPriceWrapper()}

          <div
            className={classnames(styles.tools, {
              [styles.toolsLine]: !!line
            })}>
            {profile?.role === 'ROLE_OPEN_PROFILE' && !(isSearch && isMobile) && (
              <div className={styles['counter-wrapper']}>
                <Link
                  to={`/login?return_url=${encodeURIComponent(
                    location.pathname + location.search
                  )}`}
                  className={styles.loginButton}>
                  <Trans>Zaloguj się</Trans>
                  {line ? <br /> : ' '}
                  <small>
                    <Trans>aby sprawdzić cenę</Trans>
                  </small>
                </Link>
              </div>
            )}
          </div>

          {!minimalVariant && (
            <>
              <div
                className={classnames(styles['variants-wrapper'], {
                  [styles.variantsWrapperLine]: !!line
                })}>
                {line && !isSearch && (
                  <span>
                    <Trans>Dostępne rozmiary:</Trans>
                  </span>
                )}
              </div>
              <div
                className={classnames(styles['availability-wrapper'], {
                  [styles.availabilityWrapperLine]: !!line
                })}>
                {/* temporary hide add to shopping list */}
                {/* {line && !(isSearch && isMobile) && renderAddToShoppingListButton()} */}
                <div>
                  {line && !isSearch && (
                    <span>
                      <Trans>Dostępność w magazynie:</Trans>
                    </span>
                  )}
                </div>
              </div>
            </>
          )}
          {/* temporary hide add to shopping list */}
          {/* {!line && !minimalVariant && renderAddToShoppingListButton()} */}
        </div>
      </div>
      {profile?.role !== 'ROLE_OPEN_PROFILE' && !minimalVariant && isMobile && isShoppingList && (
        <div className={styles.shoppingListMobilePriceWrapper}>
          <span>
            <Trans>Cena netto</Trans>
          </span>
          {renderPriceWrapper()}
        </div>
      )}

      {withDynamicGallery && !!product.images.length && !isMobile && (
        <div className={styles.sidebarGallery}>
          {product.images.map((image, index) => (
            <img
              itemProp="image"
              key={index}
              src={image.thumb}
              onClick={() => setGalleryIndex(index)}
            />
          ))}
        </div>
      )}
      {!isMobile && isShoppingList && handleRemove && (
        <div className={styles.removePosition}>
          <button onClick={() => handleRemove()}>
            <TrashIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductItem;
