// panel nad tabelką na stronie koszyka

import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useAppNavigate, useRWD } from 'hooks';
import { useGetCartsAll, usePutCart, useDeleteCart } from 'api';
import { ICartListItem, ICartPositionListItem } from 'api/types';
import { Button, Modal, Input, SearchInput } from 'components/controls';

import { TrashGrayIcon } from 'assets/icons';

import styles from 'theme/pages/Cart/components/ActionBar/ActionBar.module.scss';

// typ danych wejściowych
interface IProps {
  cart?: ICartListItem;
  setSearchKeyword: (searchKeyword: string) => void;
  setItemsToRemove: Dispatch<SetStateAction<ICartPositionListItem[]>>;
  checkedItemIds: number[];
  cartPositions: ICartPositionListItem[];
}

const ActionBar: FC<IProps> = ({
  cart,
  setSearchKeyword,
  setItemsToRemove,
  checkedItemIds,
  cartPositions
}) => {
  const navigate = useAppNavigate();
  const { t } = useTranslation();
  const { isMobile } = useRWD();

  // nazwa koszyka (przy edycji)
  const [cartName, setCartName] = useState(cart?.name || '');

  // czy jest widoczny modal potwierdzający usunięcie koszyka
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  // czy jest widoczny modal ze zmianą nazwy koszyka
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);

  // pobranie listy koszyków
  const { data: cartsData, refetch: refetchCarts } = useGetCartsAll();

  // skakowanie koszyka
  const { mutate: deleteCart, isLoading: isCartDeleting } = useDeleteCart(cart?.id || 0, {
    onSuccess: () => {
      const cartIdToRedirect = cartsData?.items[0]?.id || cartsData?.items[1]?.id;
      navigate(cartIdToRedirect ? `/cart/${cartIdToRedirect}` : '/');
      refetchCarts();
      setOpenConfirmationModal(false);
    }
  });

  // aktualizacja danych (nazwy) koszyka
  const {
    mutate: updateCart,
    isLoading: isCartUpdating,
    error,
    reset: resetPutCart
  } = usePutCart(cart?.id || 0, {
    onSuccess: () => {
      refetchCarts();
      setOpenChangeNameModal(false);
    }
  });

  return (
    <>
      <div
        className={classnames(
          styles.componentWrapper,
          'StylePath-Pages-Cart-Components-ActionBar'
        )}>
        <div className={styles.searchFormWrapper}>
          <SearchInput
            placeholder={t('Szukaj produktu w koszyku')}
            onChange={setSearchKeyword}
            dynamicInput
          />
        </div>
        <div>
          {isMobile ? (
            <Button
              ghost
              onClick={() => {
                setItemsToRemove(
                  cartPositions.filter((position) => checkedItemIds.includes(position.id))
                );
              }}>
              <span>
                <Trans>Usuń zaznaczone</Trans>
              </span>
            </Button>
          ) : (
            <button
              className={styles.removePositions}
              onClick={() => {
                setItemsToRemove(
                  cartPositions.filter((position) => checkedItemIds.includes(position.id))
                );
              }}>
              <TrashGrayIcon />
              <span>
                <Trans>Usuń zaznaczone</Trans>
              </span>
            </button>
          )}
        </div>
      </div>

      {openConfirmationModal && (
        <Modal title={t('Potwierdzenie usunięcia')} onClose={() => setOpenConfirmationModal(false)}>
          <div className={styles.confirmationModal}>
            <Trans>Czy napewno usunąć koszyk?</Trans>
            <div className={styles.confirmationModalActions}>
              <Button onClick={() => setOpenConfirmationModal(false)} color="secondary" ghost>
                {t('Anuluj')}
              </Button>
              <Button color="secondary" loading={isCartDeleting} onClick={() => deleteCart()}>
                {t('Usuń')}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {openChangeNameModal && (
        <Modal
          title={t('Zmień nazwę')}
          onClose={() => {
            setCartName(cart?.name || '');
            setOpenChangeNameModal(false);
            resetPutCart();
          }}>
          <div className={styles.confirmationModal}>
            <Input
              value={cartName}
              onChange={(value) => setCartName(value)}
              type="text"
              placeholder={t('Zmień nazwę')}
              error={error?.error_fields?.find((item) => item.property_path === 'name')?.message}
            />
            <div className={styles.confirmationModalActions}>
              <Button
                color="secondary"
                loading={isCartUpdating}
                onClick={() =>
                  updateCart({
                    name: cartName
                  })
                }>
                {t('Zapisz')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ActionBar;
