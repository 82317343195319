// pojedynczy typ głównego filtra

import React, { FC, useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import map from 'lodash/map';

import { Collapse, Checkbox } from 'components/controls';
import { IProductsFilter, IProductsFilterValue } from 'api/types';
import { useRWD } from 'hooks';

import { CheckboxIcon, CheckboxCheckedIcon } from 'assets/icons';

import styles from 'theme/pages/Products/components/MainFilters/MainFilters.module.scss';

interface IFilter {
  filter_id: string;
  filter_value: string;
  filter_type?: 'singlechoice' | 'multichoice' | string;
}

// typ danych wejściowych
interface IProps {
  filter: IProductsFilter;
  queryFilters: IFilter[];
  onChange: (filters: IFilter[], currentFilter: string) => void;
  clearFilters: (arg: string) => void;
  categoryId?: number;
  searchKeywords?: string;
}

const MainFilter: FC<IProps> = ({
  filter,
  queryFilters,
  onChange,
  clearFilters,
  categoryId,
  searchKeywords
}) => {
  const { isMobile } = useRWD();

  // aktualnie wyszukiwana fraza
  const [search, setSearch] = useState('');

  // lista przefiltrowanych wyników
  const [filteredResults, setFilteredResults] = useState<IProductsFilterValue[]>(filter.values);

  // resetoweanie szukanej frazy po zmianie kategorii
  useEffect(() => {
    setSearch('');
  }, [categoryId, searchKeywords]);

  // ustawienie domyślnych filtrów w sytuacji pobrania nowych filtrów
  useEffect(() => {
    setFilteredResults(
      filter.values.filter((o) => o.name.toUpperCase().match(search.toUpperCase()))
    );
  }, [filter.values]);

  const handleFilterChange = (value: IProductsFilterValue) => {
    const isSelected = queryFilters.some(
      (queryFilter) =>
        queryFilter.filter_id === filter.id &&
        queryFilter.filter_value.split(';').includes(value.value)
    );

    if (isSelected) {
      clearFilters(value.value);

      return;
    }

    ///sytuacja kiedy nie ma wybranych jeszcze żadnych filtrów
    if (!queryFilters.length) {
      onChange(
        [
          {
            filter_id: filter.id,
            filter_value: value.value,
            filter_type: filter.type
          }
        ],
        filter.id
      );

      return;
    }

    // sytuacja, kiedy już mamy jakiś filtr i wybieramy kolejny
    if (!queryFilters.find((assignedFilter) => assignedFilter.filter_id == filter.id)) {
      onChange(
        [
          ...queryFilters,
          {
            filter_id: filter.id,
            filter_value: value.value,
            filter_type: filter.type
          }
        ],
        filter.id
      );

      return;
    }

    // sytuacja, kiedy edytujemy już obecne filtry
    onChange(
      map(queryFilters, (assignedFilter) => {
        if (assignedFilter.filter_id === filter.id) {
          return {
            filter_id: filter.id,
            filter_value: value.value,
            filter_type: filter.type
          };
        }

        return assignedFilter;
      }),
      filter.id
    );
  };

  const renderFilteredResults = () => {
    return filteredResults.map((value) => (
      <div key={value.name}>
        <span className={styles.checkbox}>
          <Checkbox
            size="small"
            checked={queryFilters.some(
              (queryFilter) =>
                queryFilter.filter_id === filter.id &&
                queryFilter.filter_value.split(';').includes(value.value)
            )}
            onClick={() => handleFilterChange(value)}
            icon={<CheckboxIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
          />
        </span>
        <span>{value.name}</span>
      </div>
    ));
  };

  return (
    <Collapse
      title={filter.label}
      collapsed={!queryFilters.find((queryFilter) => queryFilter.filter_id === filter.id)}>

      <div className={styles.content}>
        {filteredResults.length ? (
          renderFilteredResults()
        ) : (
          <span className={styles.noResults}>
            <Trans>Brak wyników</Trans>
          </span>
        )}
      </div>
    </Collapse>
  );
};

export default MainFilter;
