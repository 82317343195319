// tabelka z pozycjami koszyka

import React, {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import {
  useDeleteCartPositions,
  // useGetCartsAll,
  usePutCartPosition,
  usePostCartPositionQuantityDecrement,
  usePostCartPositionQuantityIncrement
} from 'api';
import { ICartPositionListItem, IUseGetCartPositionsResponse } from 'api/types';
import { useRWD } from 'hooks';
import { Button, Modal } from 'components/controls';
import Table, { ISorter } from 'components/controls/Table';
import { getEditColumns } from './columnsEdit';
import { getViewColumns } from './columnsView';
import { getSmallColumns } from './columnsSmall';

import styles from 'theme/components/containers/CartPositions/CartPositions.module.scss';

//typ queryParams
interface IQueryParams {
  page: number;
  limit: number;
  searchKeyword: string;
  sort_method: string;
}

// typ danych wejściowych
interface IProps {
  cartId: number;
  itemsToRemove: ICartPositionListItem[];
  setItemsToRemove: Dispatch<SetStateAction<ICartPositionListItem[]>>;
  checkedItemIds: number[];
  setCheckedItemIds: Dispatch<SetStateAction<number[]>>;
  cartPositionsData: IUseGetCartPositionsResponse | undefined;
  refetchCartPositions: () => void;
  queryParams: IQueryParams;
  setQueryParams: Dispatch<SetStateAction<IQueryParams>>;
  onChange?: () => void;
  searchKeyword?: string;
  variant?: 'default' | 'noEdit' | 'small';
  noDataPlaceholder?: ReactNode;
  isCheckout?: boolean;
  isCart?: boolean;
  isCartPositionsRefetching?: boolean;
}

const CartPositions: FC<IProps> = ({
  cartId,
  itemsToRemove,
  setItemsToRemove,
  checkedItemIds,
  setCheckedItemIds,
  cartPositionsData,
  refetchCartPositions,
  queryParams,
  setQueryParams,
  onChange,
  searchKeyword = '',
  variant = 'default',
  noDataPlaceholder,
  isCart = false,
  isCheckout = false,
  isCartPositionsRefetching = false
}) => {
  const { t } = useTranslation();
  const { isMobile } = useRWD();

  // lista aktualizowanych pozycji (ID'ki)
  const [updatingPositionIds, setUpdatingPositionIds] = useState<number[]>([]);

  // informacja o sortowaniu listy
  const [sorter, setSorter] = useState<ISorter<ICartPositionListItem>>({
    by: 'name',
    direction: 'asc'
  });

  // refresh pozycji po zmianie koszyków
  // useGetCartsAll({
  //   enabled: false,
  //   onSuccess: () => refetchCartPositions()
  // });

  // skasowanie pozycji z koszyka
  const { mutate: deleteCartPositions, isLoading: isCartPositionsDeleting } =
    useDeleteCartPositions(cartId, {
      onSuccess: () => {
        onChange?.();
        refetchCartPositions();
        setItemsToRemove([]);
        setCheckedItemIds([]);
      }
    });

  // aktualizacja konkretnej pozycji (zmiana ilości)
  const { mutate: updatePosition } = usePutCartPosition(cartId);

  // zwiększenie ilości konkretnej pozycji
  const { mutate: increaseQuantity } = usePostCartPositionQuantityIncrement(cartId);

  // zmniejszenie ilości konkretnej pozycji
  const { mutate: decreaseQuantity } = usePostCartPositionQuantityDecrement(cartId);

  // aktualizacja parametrów zapytania do api przy zmianie frazy wyszukiwania
  useEffect(() => {
    setQueryParams((prevState) => ({ ...prevState, searchKeyword: searchKeyword }));
  }, [searchKeyword]);

  // funkcja wrapująca aktualizację ilości
  const handleChangeQuantity = (
    position: ICartPositionListItem,
    newValue: number | null,
    isIncrement?: boolean
  ) => {
    setUpdatingPositionIds((items) => [...items, position.id]);

    const options = {
      onSuccess: () => {
        onChange?.();
        refetchCartPositions();
      },
      onSettled: () => {
        setUpdatingPositionIds((items) => items.filter((item) => item !== position.id));
      }
    };

    newValue === null
      ? isIncrement
        ? increaseQuantity(
            {
              positionId: position.id
            },
            options
          )
        : decreaseQuantity(
            {
              positionId: position.id
            },
            options
          )
      : updatePosition(
          {
            positionId: position.id,
            quantity: newValue,
            unit_id: position.unit_id
          },
          options
        );
  };

  // wybranie wariantu tabelki
  const columns = useMemo(
    () =>
      variant === 'noEdit'
        ? getViewColumns()
        : variant === 'small'
        ? getSmallColumns({
            handleChangeQuantity,
            updatingPositionIds,
            setItemsToRemove
          })
        : getEditColumns({
            handleChangeQuantity,
            checkedItemIds,
            setCheckedItemIds,
            updatingPositionIds,
            setItemsToRemove,
            isCartPositionsRefetching,
            isMobile
          }),
    [updatingPositionIds, cartPositionsData, checkedItemIds, variant, isCartPositionsRefetching]
  );

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        [styles[variant]],
        { [styles.isCheckout]: !!isCheckout },
        'StylePath-Components-Containers-CartPositions'
      )}>
      <Table<ICartPositionListItem>
        columns={columns}
        dataSource={cartPositionsData?.items || []}
        rowKey="id"
        sorter={sorter}
        isCart={isCart}
        isCheckout={isCheckout}
        isFullTableOnMobile={isCheckout}
        onSortChange={(sorter) => {
          setSorter(sorter);
          setQueryParams({
            ...queryParams,
            sort_method: `${sorter.by}_${sorter.direction}`
          });
        }}
        pagination={{
          page: queryParams.page,
          pagesCount: cartPositionsData?.total_pages || 1,
          onChange: (page) => setQueryParams((prevState) => ({ ...prevState, page }))
        }}
      />
      {cartPositionsData && cartPositionsData.items.length === 0 && noDataPlaceholder}
      {itemsToRemove.length > 0 && (
        <Modal title={t('Potwierdzenie usunięcia')} onClose={() => setItemsToRemove([])}>
          <div className={styles.confirmationModal}>
            {t('Czy napewno usunąć poniższe produkty z koszyka?')}
            <ul>
              {itemsToRemove.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul>
            <div className={styles.confirmationModalActions}>
              <Button onClick={() => setItemsToRemove([])} ghost>
                {t('Anuluj')}
              </Button>
              <Button
                loading={isCartPositionsDeleting}
                onClick={() => {
                  deleteCartPositions({
                    cart_id: cartId,
                    positions: itemsToRemove.map((item) => ({ id: item.id }))
                  });
                }}>
                {t('Usuń')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CartPositions;
