// główny layout

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { HtmlBlock } from 'components/containers/HtmlBlock';
import { Input, Button } from 'components/controls';

import styles from 'theme/components/containers/Newsletter/Newsletter.module.scss';

const mock1 =
  "<div style='color: #555; text-transform: uppercase; text-align: center; font-family: Montserrat; font-size: 12px; font-style: normal; font-weight: 500; line-height: normal;'>Dołącz do kobiet</div><div style='color: #555; font-family: Montserrat; font-size: 32px; font-style: normal; font-weight: 400; line-height: normal; margin-bottom: 40px; margin-top: 20px; text-align: center'>10% rabatu na pierwsze zamówienie</div><div style='color: #555; text-align: center; font-family: Poppins; font-size: 12px; font-style: normal; font-weight: 300; line-height: normal; margin-bottom: 40px'><div>Zarejestruj się i otrzymaj 15% rabatu na pierwszxe zamówienie.</div> <div>Dołącz do ekskluzywnego świata Momenti Per Me</div></div>";
const mock2 =
  "<div style='padding: 40px 0; max-width: 1012px; margin: 0 auto;'> <div style='color: #908D8D;text-align: center;font-family: 'Poppins';font-size: 20px;font-style: normal;font-weight: 400;margin-bottom: 20px;line-height: normal;'>  KUPUJ TO CO POLSKIE! </div> <div style='color: #908D8D;font-family: Poppins;font-size: 12px;font-style: normal;font-weight: 400;line-height: normal;margin-bottom: 40px;'> Tworzymy dla Ciebie z pasją. Skupiamy się na szczegółach, wygodnych krojach, delikatnych wykończeniach oraz subtelnych kolorach, które idealnie współgrać będą z Twoją skórą i perfekcyjnie podkreślą Twoją kobiecość. Nasze selektywnie wybrane dzianiny i tkaniny wysokiej jakości rozpieszczą Twoją skórę, zadbają o Twój relaks i zapewnią doskonały odpoczynek podczas snu. Tworzymy dla Ciebie z pasją. Skupiamy się na szczegółach, wygodnych krojach, delikatnych wykończeniach oraz subtelnych kolorach, które idealnie współgrać będą z Twoją skórą i perfekcyjnie podkreślą Twoją kobiecość. Nasze selektywnie wybrane dzianiny i tkaniny wysokiej jakości rozpieszczą Twoją skórę, zadbają o Twój relaks i zapewnią doskonały odpoczynek podczas snu. </div> <div style='color: #908D8D;text-align: center;font-family: 'Poppins';font-size: 20px;font-style: normal;font-weight: 400;margin-bottom: 20px;line-height: normal;'> WYSOKA JAKOŚĆ </div> <div style='color: #908D8D;font-family: Poppins;font-size: 12px;font-style: normal;font-weight: 400;line-height: normal;margin-bottom: 40px;'> Tworzymy dla Ciebie z pasją. Skupiamy się na szczegółach, wygodnych krojach, delikatnych wykończeniach oraz subtelnych kolorach, które idealnie współgrać będą z Twoją skórą i perfekcyjnie podkreślą Twoją kobiecość. Nasze selektywnie wybrane dzianiny i tkaniny wysokiej jakości rozpieszczą Twoją skórę, zadbają o Twój relaks i zapewnią doskonały odpoczynek podczas snu. </div></div>";

const Newsletter = () => {
  const { t } = useTranslation();
  return (
    <div className={classnames(styles.componentWrapper, 'StylePath-Pages-Home-Newsletter')}>
      <div className={styles.newsletterTop}>
        <div dangerouslySetInnerHTML={{ __html: mock1 }} />
        <div className={styles.newsletter}>
          <Input placeholder={t('Wpisz swój e-mail...')} />
          <Button>
            <Trans>Subskrybuj</Trans>
          </Button>
        </div>
      </div>
      <div>
        <div dangerouslySetInnerHTML={{ __html: mock2 }} />
      </div>
    </div>
  );
};

export default Newsletter;
