// przycisk z listą podglądu kkoszyków

import React, { useMemo, useState, useEffect } from 'react';
import classnames from 'classnames';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { reduxActions, useDispatch, useSelector } from 'store';
import { useRWD } from 'hooks';
import { useGetCartsAll, useGetCartPositions } from 'api';
import { ICartPositionListItem } from 'api/types';
import { Link } from 'components/controls';
import { MiniCartIcon } from 'assets/icons';
import CartButtonPositions from './components/CartButtonPositions';
import ExpiredPayments from './components/ExpiredPayments';

import { CartMobile } from 'assets/icons';

import styles from 'theme/components/containers/CartsButton/CartsButton.module.scss';

const ButtonCart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useRWD();
  const navigate = useNavigate();

  // czy jest ustawiony element HTML popovera (użyte przy pokazywaniu modala)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null);

  // lista pozycji do usunięcia
  const [itemsToRemove, setItemsToRemove] = useState<ICartPositionListItem[]>([]);

  // lista zaznaczonyxh pozycji (ID'ki)
  const [checkedItemIds, setCheckedItemIds] = useState<number[]>([]);

  // parametry zapytania API
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    searchKeyword: '',
    sort_method: ''
  });

  // ID domyślnego koszyka
  const { currentCartId } = useSelector((state) => state.cart);

  // pobranie listy koszyków
  const {
    data: cartsData,
    refetch: refetchCartsData,
    isFetching: isCartsFetching
  } = useGetCartsAll({
    onSuccess: (data) => {
      !currentCartId && dispatch(reduxActions.setCurrentCartId(data.items[0]?.id) || null);
    }
  });

  // pobranie listy pozycji koszyka
  const { data: cartPositionsData, refetch: refetchCartPositions } = useGetCartPositions(
    currentCartId || 0,
    queryParams,
    {
      enabled: !!currentCartId
    }
  );

  // domyślny koszyk
  const currentCart = useMemo(
    () => cartsData?.items.find((cart) => currentCartId === cart.id),
    [currentCartId, cartsData]
  );

  useEffect(() => {
    if (popoverAnchor) {
      refetchCartsData();
      refetchCartPositions();
    }
  }, [popoverAnchor]);

  // ustawienie domyślnego koszyka po pobraniu/aktualizacji listy koszyków
  useEffect(() => {
    !isCartsFetching &&
      !currentCart &&
      dispatch(reduxActions.setCurrentCartId(cartsData?.items[0]?.id || null));
  }, [isCartsFetching, currentCart, cartsData]);

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-CartsButton'
      )}>
      {isMobile ? (
        <button className={styles.cartMobile} onClick={() => navigate(`/cart/${currentCartId}`)}>
          <CartMobile />
          <span className={styles.badge}>{currentCart?.products_count}</span>
        </button>
      ) : (
        <Badge
          color="secondary"
          badgeContent={0}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
          <div
            className={classnames(styles.button, { [styles.active]: popoverAnchor })}
            onClick={(event) => setPopoverAnchor(event.currentTarget)}>
            <MiniCartIcon className={styles.icon} />
            {cartPositionsData?.items.length && (
              <div className={styles.badge}>{cartPositionsData?.items.length}</div>
            )}
          </div>
        </Badge>
      )}

      <Popover
        PaperProps={{ square: true }}
        anchorEl={popoverAnchor}
        open={!!popoverAnchor && !isMobile}
        onClose={() => setPopoverAnchor(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        <div className={styles.cartWrapper}>
          {currentCartId && <ExpiredPayments currentCartId={currentCartId} />}
          <div className={styles.cartHeader}>
            <Trans>Koszyk</Trans>
          </div>
          <div className={styles.cartBody}>
            {currentCartId && (currentCart?.products_count || 0) > 0 ? (
              <CartButtonPositions
                currentCartId={currentCartId}
                itemsToRemove={itemsToRemove}
                setItemsToRemove={setItemsToRemove}
                checkedItemIds={checkedItemIds}
                setCheckedItemIds={setCheckedItemIds}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                refetchCartsData={refetchCartsData}
                cartPositionsData={cartPositionsData}
                refetchCartPositions={refetchCartPositions}
              />
            ) : (
              <div className={styles.emptyCart}>
                <span className={styles.title}>{t('Twój koszyk jest pusty')}!</span>
                {t('Sprawdź dostępne produkty!')}
              </div>
            )}
          </div>

          {(currentCart?.products_count || 0) > 0 && (
            <div className={styles.cartFooter}>
              <div className={styles.total}>
                <span className={styles.label}>{t('Suma')}:</span>
                <div className={styles.prices}>
                  <span className={styles.net}>
                    <span>
                      {currentCart?.positions_value_net_formatted.replace('.', ',')}{' '}
                      {currentCart?.currency}
                    </span>{' '}
                    {t('netto')}
                  </span>
                </div>
              </div>

              <div className={styles.actions} onClick={() => setPopoverAnchor(null)}>
                <Link to={`/cart/${currentCartId}`}>
                  {t('Przejdź do koszyka')}
                  {'>>'}
                </Link>
              </div>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default ButtonCart;
