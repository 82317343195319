// przycisk z listą podglądu kkoszyków

import React, { useState, FC } from 'react';
import classnames from 'classnames';
import Popover from '@mui/material/Popover';

import { useSelector } from 'store';
import { useRWD, useAppNavigate } from 'hooks';
import { Login } from 'pages';

import { UserMobileIcon, UserLargeIcon, UserWhiteIcon } from 'assets/icons';

import styles from 'theme/components/containers/LoginButton/LoginButton.module.scss';

// typ danych wejściowych
interface IProps {
  onClose?: () => void;
  isHomePage?: boolean;
}

const ButtonCart: FC<IProps> = ({ onClose, isHomePage }) => {
  const { profile } = useSelector((state) => state.auth);

  const { isMobile } = useRWD();

  const navigate = useAppNavigate();

  // czy jest ustawiony element HTML popovera (użyte przy pokazywaniu modala)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-LoginButton'
      )}>
      <button
        className={styles.authButton}
        onClick={(event) => {
          if (profile?.role === 'ROLE_OPEN_PROFILE') {
            onClose?.();
            setPopoverAnchor(event.currentTarget);

            return;
          }

          navigate('/dashboard');
        }}>
        {isMobile ? <UserMobileIcon /> : isHomePage ? <UserWhiteIcon /> : <UserLargeIcon />}
      </button>

      <Popover
        anchorEl={popoverAnchor}
        classes={{ paper: styles.paper }}
        open={!!popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        marginThreshold={50}>
        <Login isPopover setPopoverAnchor={setPopoverAnchor} />
      </Popover>
    </div>
  );
};

export default ButtonCart;
