// Strona pojedynczego produktu

import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Grid } from '@mui/material';

import { useGetProduct, useGetProductBreadcrumbs } from 'api';
import { IPosition, IProductsBreadcrumbsRequest } from 'api/types';
import { reduxActions, useDispatch } from 'store';
import { Container, Breadcrumbs, Loader } from 'components/controls';
import { Details, Gallery } from './components';
import { HtmlBlock } from 'components/containers/HtmlBlock';
import ProductsBox from 'components/containers/ProductsBox';

import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from 'theme/pages/Product/Product.module.scss';

const Product = ({ id }: { id?: string }) => {
  const dispatch = useDispatch();

  // ID produktu (przekształcony na int)
  const { id: paramId } = useParams();
  const productId = useMemo(() => parseInt(paramId || id || ''), [id]);

  // ID kategorii lub fraza wyszukiwania potrzebne jako param w breadcrumbs
  const { state } = useLocation();
  const { categoryId, searchKeywords, mode } = (state || {}) as IProductsBreadcrumbsRequest;

  // Ustawianie danych do dodania do koszyka
  const [positions, setPositions] = useState<IPosition[]>([]);

  // Pobranie produktu
  const { data: productData, isLoading: isProductLoading } = useGetProduct(productId, {
    enabled: !!productId
  });

  // Pobranie listy breadcrumbs'ów danego produktu
  const { data: productBreadcrumbsData } = useGetProductBreadcrumbs(
    productId,
    {
      ...(categoryId ? { categoryId } : {}),
      ...(searchKeywords ? { searchKeywords } : {}),
      ...(mode ? { mode } : {})
    },
    {
      enabled: !!productId
    }
  );

  // funkcja skrolująca stronę do góry
  const scrollToTop = useCallback(
    () => document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
    []
  );

  // scrollowanie do góry strony
  useEffect(() => {
    scrollToTop();
  }, []);

  // ustawienie id produktu w localstorage
  useEffect(() => {
    localStorage.setItem('PRODUCT_ID', id || '');
  }, []);

  // Ustawienie breadcrums'ów do wyświetlenia JCZ: w jakim momencie ? Po załadowaniu danych ?
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs(
        productBreadcrumbsData
          ? productBreadcrumbsData.items.map((item) => ({
              name: item.name,
              path: item.category_id
                ? `/products?category_id=${item.category_id}&search_keyword=`
                : mode
                ? `/products?mode=${mode}`
                : undefined
            }))
          : [
              {
                name: 'Produkty',
                path: '/products'
              },
              {
                name: productData?.title || 'Produkt'
              }
            ]
      )
    );
  }, [productData, productBreadcrumbsData]);

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Product')}>
      {productData && (
        <Helmet>
          <title>{productData.title} - VMP</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}

      <Breadcrumbs />
      {isProductLoading && <Loader />}
      {productData && (
        <>
          <div
            className={styles.productDetailsWrapper}
            itemScope
            itemType="http://schema.org/Product">
            <Container>
              <Grid container>
                <div className={styles.galleryWrapper}>
                  <Gallery
                    images={productData.images}
                    files={productData.files}
                    labels={productData.labels}
                  />
                </div>
                <Grid className={styles.detailsWrapper}>
                  <Details
                    product={productData}
                    labels={productData.labels}
                    positions={positions}
                    setPositions={setPositions}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
          {productData.id && (
            <div className={styles.getInspired}>
              <HtmlBlock sectionId="GET_INSPIRED_SECTION" articleId={productData.id.toString()} />
            </div>
          )}
          <ProductsBox productBoxId="BOX_01" />
        </>
      )}
    </div>
  );
};

export default Product;
