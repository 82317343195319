// stopka głównego layoutu

import React, { FC } from 'react';
import classnames from 'classnames';

import { Link } from 'components/controls';
import { HtmlBlock } from 'components/containers/HtmlBlock';

import { InstagramIcon, FacebookIcon, ContactWhiteIcon } from 'assets/icons';

import styles from 'theme/components/layouts/MainLayout/components/Footer/Footer.module.scss';

// typ danych wejściowych
interface IProps {
  isHomePage?: boolean;
}

const Footer: FC<IProps> = ({ isHomePage }) => {
  const renderSocials = () => (
    <>
      <Link className={styles.icon} to="/">
        <InstagramIcon />
      </Link>
      <Link className={styles.icon} to="/">
        <FacebookIcon />
      </Link>
      <Link className={styles.icon} to="/">
        <ContactWhiteIcon />
      </Link>
    </>
  );

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        { [styles.isHomePage]: !!isHomePage },
        'StylePath-Components-Layouts-MainLayout-Components-Footer'
      )}>
      <HtmlBlock sectionId="FOOTER" />
      <div className={styles.socials}>{renderSocials()}</div>
    </div>
  );
};

export default Footer;