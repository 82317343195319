import React, { FC, useState, useEffect } from 'react';
import { Range as RangeInput } from 'react-range';
import { Trans } from 'react-i18next';
import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';

import { IProductsFilter } from 'api/types';
import { Button } from 'components/controls';
import { usePrevious } from 'hooks';

import styles from 'theme/pages/Products/components/MainFilters/MainFilters.module.scss';

interface IFilter {
  filter_id: string;
  filter_value: string;
  filter_type?: 'singlechoice' | 'multichoice' | string;
}

// typ danych wejściowych
interface IProps {
  queryFilters: IFilter[];
  onChange: (filters: IFilter[], currentFilter: string) => void;
  filter: IProductsFilter;
}

const Range: FC<IProps> = ({ filter, queryFilters, onChange }) => {
  const [values, setValues] = useState([
    parseFloat(filter.values[0].value),
    parseFloat(filter.values[1].value)
  ]);

  // wartośc min inputa
  const [minInputValue, setMinInputValue] = useState(filter.values[0].value);

  // wartośc max inputa
  const [maxInputValue, setMaxInputValue] = useState(filter.values[1].value);

  // poprzednia wartość filtrów
  const prevFilter = usePrevious(filter);

  // zmiana zakresu filtrów po zmianie kategorii
  useEffect(() => {
    if (!isEqual(filter, prevFilter)) {
      setValues([parseFloat(filter.values[0].value), parseFloat(filter.values[1].value)]);
      setMinInputValue(filter.values[0].value);
      setMaxInputValue(filter.values[1].value);
    }
  }, [filter]);

  // ustawianie wartości domyślnych aktualnie wybranego filtra
  useEffect(() => {
    const priceFilter = queryFilters.find((o) => o.filter_id === filter.id);

    if (priceFilter) {
      const priceFilterValues = priceFilter.filter_value.split(';');
      setValues([parseFloat(priceFilterValues[0]), parseFloat(priceFilterValues[1])]);
      setMinInputValue(priceFilterValues[0]);
      setMaxInputValue(priceFilterValues[1]);

      return;
    }

    // resetowanie wartości przy czyszczeniu filtrów
    setValues([parseFloat(filter.values[0].value), parseFloat(filter.values[1].value)]);
    setMinInputValue(filter.values[0].value);
    setMaxInputValue(filter.values[1].value);
  }, [queryFilters]);

  const handleInputChange = (inputType: 'min' | 'max') => {
    const minValueFormatted =
      isNaN(parseFloat(minInputValue)) ||
      parseFloat(minInputValue) < parseFloat(filter.values[0].value)
        ? filter.values[0].value
        : minInputValue;
    const maxValueFormatted =
      isNaN(parseFloat(maxInputValue)) ||
      parseFloat(maxInputValue) > parseFloat(filter.values[1].value)
        ? filter.values[1].value
        : maxInputValue;

    if (parseFloat(maxValueFormatted) < parseFloat(minValueFormatted) && inputType === 'max') {
      setMaxInputValue(values[1].toFixed(2));

      return;
    }

    if (parseFloat(minValueFormatted) > parseFloat(maxValueFormatted) && inputType === 'min') {
      setMinInputValue(values[0].toFixed(2));

      return;
    }

    setMinInputValue(parseFloat(minValueFormatted).toFixed(2));
    setMaxInputValue(parseFloat(maxValueFormatted).toFixed(2));

    setValues([parseFloat(minValueFormatted), parseFloat(maxValueFormatted)]);
  };

  return (
    <div className={styles.priceWrapper}>
      <div className={styles.priceTitle}>
        <Trans>Cena</Trans>
      </div>
      <div>
        <div className={styles.inputWrapper}>
          <input
            value={minInputValue.replace('.', ',')}
            onChange={(e: any) => {
              if (/^[\d|.|,]+/.test(e.nativeEvent.data) || e.nativeEvent.data === null) {
                setMinInputValue(e.target.value.replace(',', '.'));
              }
            }}
            onBlur={() => handleInputChange('min')}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleInputChange('min');
              }
            }}
          />
          <div className={styles.inputDash} />
          <input
            value={maxInputValue.replace('.', ',')}
            onChange={(e: any) => {
              if (/^[\d|.|,]+/.test(e.nativeEvent.data) || e.nativeEvent.data === null) {
                setMaxInputValue(e.target.value.replace(',', '.'));
              }
            }}
            onBlur={() => handleInputChange('max')}
          />
        </div>
        {parseFloat(filter.values[0].value) === parseFloat(filter.values[1].value) ||
        values[0] === values[1] ? (
          <div className={styles.rangePlaceholder} />
        ) : (
          <RangeInput
            step={0.01}
            min={parseFloat(filter.values[0].value)}
            max={parseFloat(filter.values[1].value)}
            values={values}
            onChange={(values) => {
              // obliczanie minimalnego dystansu pomiędzy max - min (założenie 10%)
              const distance =
                Math.abs(parseFloat(filter.values[1].value) - parseFloat(filter.values[0].value)) /
                15;
              if (Math.abs(values[0] - values[1]) < distance) {
                return;
              }

              setMinInputValue(values[0].toFixed(2));
              setMaxInputValue(values[1].toFixed(2));
              setValues(values);
            }}
            renderTrack={({ props, children }: any) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '2px',
                  width: '100%',
                  backgroundColor: '#E9E9E9'
                }}>
                <div
                  style={{
                    width: `${
                      ((values[1] - values[0]) * 100) /
                      (parseFloat(filter.values[1].value) - parseFloat(filter.values[0].value))
                    }%`,
                    left: `${
                      ((values[0] - parseFloat(filter.values[0].value)) * 100) /
                      (parseFloat(filter.values[1].value) - parseFloat(filter.values[0].value))
                    }%`
                  }}
                  className={styles.innerTrack}></div>
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '15px',
                  width: '15px',
                  backgroundColor: '#D9D9D9',
                  borderRadius: '50%'
                }}
              />
            )}
          />
        )}
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          ghost
          square
          onClick={() => {
            onChange([...remove(queryFilters, (o) => o.filter_id !== filter.id)], filter.id);
            setMinInputValue(filter.values[0].value);
            setMaxInputValue(filter.values[1].value);
            setValues([parseFloat(filter.values[0].value), parseFloat(filter.values[1].value)]);
          }}>
          <Trans>Wyczyść</Trans>
        </Button>
        <Button
          square
          onClick={() => {
            onChange(
              [
                ...queryFilters.filter((assignedFilter) => assignedFilter.filter_id !== filter.id),
                {
                  filter_id: filter.id,
                  filter_value: `${values[0].toFixed(2)};${values[1].toFixed(2)}`,
                  filter_type: filter.type
                }
              ],
              filter.id
            );
          }}>
          <Trans>Zastosuj</Trans>
        </Button>
      </div>
    </div>
  );
};

export default Range;
