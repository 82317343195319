import React, { FC, Dispatch, SetStateAction } from 'react';

import { ICartPositionListItem, IUseGetCartPositionsResponse } from 'api/types';

import { CartPositions } from 'components/containers';

//typ queryParams
interface IQueryParams {
  page: number;
  limit: number;
  searchKeyword: string;
  sort_method: string;
}

// typ danych wejściowych
interface IProps {
  currentCartId: number;
  itemsToRemove: ICartPositionListItem[];
  setItemsToRemove: Dispatch<SetStateAction<ICartPositionListItem[]>>;
  checkedItemIds: number[];
  setCheckedItemIds: Dispatch<SetStateAction<number[]>>;
  queryParams: IQueryParams;
  setQueryParams: Dispatch<SetStateAction<IQueryParams>>;
  refetchCartsData: () => void;
  refetchCartPositions: () => void;
  cartPositionsData?: IUseGetCartPositionsResponse;
}

const CartButtonPositions: FC<IProps> = ({
  currentCartId,
  itemsToRemove,
  setItemsToRemove,
  checkedItemIds,
  setCheckedItemIds,
  queryParams,
  setQueryParams,
  refetchCartsData,
  cartPositionsData,
  refetchCartPositions
}) => {
  return (
    <CartPositions
      cartId={currentCartId}
      itemsToRemove={itemsToRemove}
      setItemsToRemove={setItemsToRemove}
      checkedItemIds={checkedItemIds}
      setCheckedItemIds={setCheckedItemIds}
      cartPositionsData={cartPositionsData}
      refetchCartPositions={refetchCartPositions}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      onChange={() => refetchCartsData()}
    />
  );
};

export default CartButtonPositions;
