import React, { FC, Dispatch, SetStateAction, useMemo } from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import { X, Check2 } from 'react-bootstrap-icons';

import { IProduct, IProductVersion, IPosition } from 'api/types';
import { useRWD } from 'hooks';

import { Table, Collapse } from 'components/controls';
import { IColumn } from 'components/controls/Table';
import Prices from '../Prices';

import columnsSimple from './columnsSimple';

import styles from 'theme/pages/Product/components/SizingTable/SizingTable.module.scss';

// typ danych wejściowych
interface IProps {
  product: IProduct;
  positions: IPosition[];
  setPositions: Dispatch<SetStateAction<IPosition[]>>;
}

const SizingTable: FC<IProps> = ({ product, positions, setPositions }) => {
  const { isMobile } = useRWD();

  const changeProductPositions = (position: IPosition) => {
    const index = findIndex(positions, (o) => o.product_id === position.product_id);
    if (index === -1) {
      setPositions([...positions, position]);

      return;
    }

    if (!position.quantity) {
      setPositions(filter(positions, (o) => o.product_id !== position.product_id));
      return;
    }

    setPositions(map(positions, (o, k) => (k === index ? position : o)));
  };

  // aktualna jednostka
  const unit = useMemo(
    () => product.units.find((unit) => unit.unit_id === product.units[0]?.unit_id),
    [product]
  );

  const renderCell = (qty: number) => {
    if (qty === -1) {
      return <Check2 size={27} color="#44d038" />;
    }

    if (qty === 0) {
      return <X size={27} color="#CD4E5D" />;
    }

    return <span>{qty}</span>;
  };

  const prepareMobileColumns = (columns: IColumn<IProductVersion>[]) => {
    const [color, ...restElements] = columns;

    return restElements;
  };

  const renderTableContent = (dataSource: IProductVersion[], color: string) => {
    const columns = columnsSimple({
      renderCell,
      isMobile,
      unit,
      product,
      positions,
      changeProductPositions
    });

    if (isMobile) {
      return (
        <Collapse
          title={
            <div className={styles.collapseTitle}>
              <Trans>Kolor</Trans>: <span>{color}</span>
            </div>
          }
          collapsed={false}>
          <Table
            key={color}
            columns={prepareMobileColumns(columns)}
            dataSource={dataSource}
            rowKey="product_id"
            isFullTableOnMobile={true}
          />
        </Collapse>
      );
    }

    return (
      <div
        className={classnames({
          [styles.extended]: !!dataSource[0].different_warehouse_prices
        })}>
        <Table key={color} columns={columns} dataSource={dataSource} rowKey="product_id" />
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Product-components-SizingTable'
      )}>
      {product.versions && map(groupBy(product.versions, 'color'), renderTableContent)}
      {!!product.versions.length && (
        <div
          className={classnames(styles.buttonsWrapper, {
            [styles.priceExtended]: !!product.different_warehouses_prices
          })}>
          <Prices product={product} positions={positions} setPositions={setPositions} unit={unit} />
        </div>
      )}
    </div>
  );
};

export default SizingTable;
