// Przycisk dodawania pozycji do listy zakupowej

import React, { FC, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';

import { IProductListItem, IUnit } from 'api/types';
import { Modal, Button } from 'components/controls';
import { AddToShoppingListForm } from 'components/containers';

import heart from 'assets/images/heart.png';
import heartFilled from 'assets/images/heart-filled.png';

import styles from 'theme/components/containers/AddToShoppingListButton/AddToShoppingListButton.module.scss';
import { useRWD } from 'hooks';

// typ danych wejściowych
interface IProps {
  product: IProductListItem;
  unit?: IUnit;
  quantity: number;
  openModal?: boolean;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isShoppingList?: boolean;
  isProductPage?: boolean;
}

const AddToShoppingListButton: FC<IProps> = ({
  product,
  unit,
  quantity,
  openModal,
  setOpenModal,
  isShoppingList,
  isProductPage
}) => {
  const { t } = useTranslation();
  const { isMobile } = useRWD();

  // czy jest modal dodawania do listy zakupowej
  const [isAddToShoppingListModal, setIsAddToShoppingListModal] = useState(false);

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-AddToShoppingListButton'
      )}>
      <div
        className={classnames(styles.shoppingListButton, {
          [styles.isProductPage]: !!isProductPage
        })}
        onClick={() => {
          // temporary disable shopping list
          // setOpenModal ? setOpenModal(true) : setIsAddToShoppingListModal(true);
        }}>
        {isProductPage ? (
          <Button ghost>
            <Trans>Dodaj do ulubionych</Trans>
            <img src={isShoppingList ? heartFilled : heart} />
          </Button>
        ) : (
          <img src={isShoppingList ? heartFilled : heart} />
        )}
      </div>

      {(openModal || isAddToShoppingListModal) && !isShoppingList && (
        <Modal
          fullScreen={isMobile}
          title={t('Dodawanie produktów do listy zakupowej')}
          onClose={() => {
            setOpenModal ? setOpenModal(false) : setIsAddToShoppingListModal(false);
          }}>
          <AddToShoppingListForm
            items={[
              {
                product_id: product.id,
                unit_id: unit?.unit_id || product.default_unit_id,
                quantity: quantity,
                image: product.images[0]?.thumb,
                title: product.title,
                index: product.index,
                price_net_formatted: unit?.price_net_formatted,
                price_gross_formatted: unit?.price_gross_formatted,
                currency: product.currency
              }
            ]}
            onCancel={() => {
              setOpenModal ? setOpenModal(false) : setIsAddToShoppingListModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default AddToShoppingListButton;
