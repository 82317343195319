// podsumowanie koszyka - box w sidebar

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import map from 'lodash/map';

import { useGetCart, useGetCartValidatingMessage } from 'api';
import { useAppNavigate, useRWD } from 'hooks';
import { Button } from 'components/controls';
import { ICartData, ICartConfirmRequest } from 'api/types';

import styles from 'theme/components/containers/CartSummary/CartSummary.module.scss';

// typ danych wejściowych
interface IProps {
  cartId: number;
  buttonOnClick: (data?: ICartConfirmRequest) => void;
  clearCart?: () => void;
  buttonLabel?: string;
  isLoading?: boolean;
  isSummary?: boolean;
  isMobileCheckoutTop?: boolean;
  isMobileCheckoutBottom?: boolean;
}

const CartSummary: FC<IProps> = ({
  cartId,
  buttonOnClick,
  isLoading,
  clearCart,
  isSummary,
  isMobileCheckoutTop,
  isMobileCheckoutBottom
}) => {
  const navigate = useAppNavigate();
  const { isMobile } = useRWD();

  // pobranie szczegółów koszyka
  const { data: cartData } = useGetCart(cartId, {
    onSuccess: () => {
      refetchValidatingMessage();
    },
    onError: () => {
      navigate('/');
    }
  });

  const { data: validatingMessageData, refetch: refetchValidatingMessage } =
    useGetCartValidatingMessage(cartId);

  const renderMissingOrder = (cartData: ICartData) => {
    return (
      <span className={styles.missingOrder}>
        <Trans>Brakuje</Trans> {cartData.missing_to_order_net_formatted} {cartData.currency}{' '}
        <Trans>netto</Trans>, <Trans>aby złożyć zamówienie u producenta.</Trans>
      </span>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        isSummary && styles.isSummary,
        'StylePath-Components-Containers-CartSummary',
        { [styles.isMobileCheckoutTop]: !!isMobileCheckoutTop },
        { [styles.isMobileCheckoutBottom]: !!isMobileCheckoutBottom }
      )}>
      {!isSummary && (
        <div>
          {cartData?.has_free_delivery &&
          typeof cartData.amount_to_free_delivery_formatted === 'string' ? (
            <span className={styles.freeDelivery}>
              <Trans>Dodano darmową wysyłkę!</Trans>
            </span>
          ) : (
            cartData?.amount_to_free_delivery && (
              <span className={styles.freeDeliveryMissing}>
                <Trans>Brakuje</Trans>{' '}
                <b>
                  {cartData?.amount_to_free_delivery_formatted.replace('.', ',')}{' '}
                  {cartData?.currency} <Trans>netto</Trans>
                </b>{' '}
                <Trans>do darmowej wysyłki</Trans>
              </span>
            )
          )}
          {cartData?.upcoming_invoice_payments_response && (
            <div className={styles.invoices}>
              <Trans>Zbliża się termin płatności następujących faktur:</Trans>
              {cartData?.upcoming_invoice_payments_response.map((invoice, index) => (
                <div key={index}>{invoice}</div>
              ))}
            </div>
          )}
          {cartData?.merchant_limit_net_formatted && (
            <span className={styles.merchantLimit}>
              <Trans>Limit kupiecki został przekroczony o</Trans>{' '}
              <b>
                {cartData.merchant_limit_net_formatted.replace('.', ',')} {cartData.currency}
              </b>
            </span>
          )}
        </div>
      )}

      <div>
        {!isMobile &&
          cartData?.missing_to_order_net_formatted &&
          !isSummary &&
          renderMissingOrder(cartData)}
        {!isSummary &&
          validatingMessageData?.items.map((item, i) => (
            <p className={styles.validatingMessage} key={i}>
              {item.message}
            </p>
          ))}
        {/* {!isSummary && (
          <div className={styles.discountCode}>
            <span>
              <Trans>Kod rabatowy</Trans>:
            </span>
            <input />
            <Button>
              <Trans>Zastosuj</Trans>
            </Button>
          </div>
        )} */}
        {isMobile &&
          cartData?.missing_to_order_net_formatted &&
          !isSummary &&
          renderMissingOrder(cartData)}
        <div className={styles.actionsWrapper}>
          {!isMobileCheckoutBottom && (
            <div className={styles.priceWrapper}>
              <div className={styles.valueTotalNet}>
                <div>
                  <Trans>Wartość netto</Trans>
                </div>
                <div className={styles.value}>
                  {cartData?.total_price_net_formatted ? (
                    <>
                      {cartData.total_discount_net && (
                        <span className={styles.oldPrice}>
                          {cartData.value_base_net_with_services_formatted?.replace('.', ',')}{' '}
                          {cartData.currency}
                        </span>
                      )}{' '}
                      <span className={classnames(cartData.total_discount_net && styles.discount)}>
                        {isSummary
                          ? cartData.total_price_net_formatted.replace('.', ',')
                          : cartData.positions_value_net_formatted.replace('.', ',')}{' '}
                        {cartData.currency}
                      </span>{' '}
                    </>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              {isSummary &&
                map(cartData?.tax_details.tax_list, (tax) => (
                  <div className={styles.grossValue}>
                    <div>
                      <Trans>VAT</Trans> {tax.tax_rate}%
                    </div>
                    <div>
                      {tax.total_sum_tax_formatted?.replace('.', ',')} {cartData?.currency}
                    </div>
                  </div>
                ))}

              <div className={styles.valueTotalGross}>
                <div>
                  <Trans>Wartość brutto</Trans>
                </div>
                <div className={styles.value}>
                  {cartData?.total_price_gross_formatted ? (
                    <>
                      {cartData.total_discount_gross && (
                        <span className={styles.oldPrice}>
                          {cartData.value_base_gross_with_services_formatted?.replace('.', ',')}{' '}
                          {cartData.currency}
                        </span>
                      )}{' '}
                      <span
                        className={classnames(
                          cartData.total_old_price_gross_formatted && styles.discount
                        )}>
                        {isSummary
                          ? cartData.total_price_gross_formatted.replace('.', ',')
                          : cartData.positions_value_gross_formatted.replace('.', ',')}{' '}
                        {cartData.currency}
                      </span>{' '}
                    </>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </div>
              {cartData?.total_discount_gross_formatted && (
                <div className={styles.totalDiscount}>
                  <Trans>Oszczędzasz</Trans>{' '}
                  <b>
                    {cartData.total_discount_gross_formatted?.toString().replace('.', ',')}{' '}
                    {cartData.currency}
                  </b>
                </div>
              )}
            </div>
          )}

          <div className={styles.buttonWrapper}>
            {isSummary && !isMobileCheckoutTop ? (
              <>
                <Button onClick={() => buttonOnClick({ enabled: true })} loading={isLoading}>
                  <Trans>Potwierdzam i proszę o realizację</Trans>
                </Button>
                <Button onClick={() => buttonOnClick({ enabled: false })} loading={isLoading}>
                  <Trans>Potwierdzam i proszę o wstrzymanie</Trans>
                </Button>
                <Button onClick={() => navigate('/')} ghost>
                  <Trans>Wróć do zakupów</Trans>
                </Button>
              </>
            ) : (
              !isMobileCheckoutTop && (
                <>
                  <Button onClick={() => buttonOnClick()} loading={isLoading}>
                    <Trans>Złóż zamówienie</Trans>
                  </Button>
                  <Button ghost onClick={() => navigate('/')}>
                    <Trans>Kontynuuj zakupy</Trans>
                  </Button>
                  {!isMobile && clearCart && (
                    <Button ghost onClick={() => clearCart()}>
                      <Trans>Wyczyść koszyk</Trans>
                    </Button>
                  )}
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
