import React, { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import classnames from 'classnames';

import { usePutOrderEditClientNote } from 'api';
import { IOrderVmp } from 'api/types';
import { useRWD } from 'hooks';

import { Modal, Button } from 'components/controls';
import { NotesIcon } from 'assets/icons';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

interface IProps {
  orderData: IOrderVmp;
  refetchOrderData: () => void;
  isEditingMode: boolean;
}

const PaymentEdit: FC<IProps> = ({ orderData, refetchOrderData, isEditingMode }) => {
  const { t } = useTranslation();
  const { isMobile } = useRWD();

  // edycja komentarza
  const { mutate: updateComment } = usePutOrderEditClientNote(orderData.id, {
    onSuccess: () => {
      setIsCommentEditModal(false);
      refetchOrderData();
    }
  });

  // czy widoczny jest modal
  const [isCommentEditModal, setIsCommentEditModal] = useState(false);

  // edycja komentarza
  const [comment, setComment] = useState(orderData.client_notes);

  // uaktualnienie danych
  useEffect(() => {
    setComment(orderData.client_notes);
  }, [orderData]);

  const handleSubmit = () => {
    updateComment({ note: comment, order_id: orderData.id });
  };

  const renderTextField = () => (
    <TextareaAutosize
      className={styles.textarea}
      value={comment}
      minRows={isMobile ? 4 : 7}
      onChange={(event) => setComment(event.target.value)}
    />
  );

  return (
    <Grid className={classnames(styles.box, styles.comment)} item xs={12} lg={4}>
      <div className={styles.inner}>
        <div className={styles.boxTitle}>
          <div>
            <NotesIcon />
            <Trans>Uwagi</Trans>
          </div>

          {isEditingMode && (
            <button onClick={() => setIsCommentEditModal(true)} className={styles.changeBtn}>
              <Trans>Zmień</Trans>
            </button>
          )}
        </div>
        {isMobile ? (
          <div className={styles.commentEdit}>{renderTextField()}</div>
        ) : (
          <div className={styles.value}>{orderData.client_notes}</div>
        )}
      </div>

      {isCommentEditModal && !isMobile && (
        <Modal title={t('Edytuj komentarz')} onClose={() => setIsCommentEditModal(false)}>
          <div className={styles.commentEdit}>
            {renderTextField()}
            <div className={styles.actions}>
              <Button ghost onClick={() => setIsCommentEditModal(false)}>
                <Trans>Anuluj</Trans>
              </Button>
              <Button onClick={handleSubmit}>
                <Trans>Zapisz</Trans>
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </Grid>
  );
};

export default PaymentEdit;
