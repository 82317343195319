// główny layout - górna belka nagłówka

import React from 'react';
import classnames from 'classnames';

import { HtmlBlock } from 'components/containers/HtmlBlock';

import styles from 'theme/components/layouts/MainLayout/components/HeaderTopBar/HeaderTopBar.module.scss';

const HeaderTopBar = () => {
  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-HeaderTopBar'
      )}>
      <HtmlBlock sectionId='TOP_BAR' />
    </div>
  );
};

export default HeaderTopBar;